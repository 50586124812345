var DermatologyM = (function () {
	"use strict";

	var windowWidth,
		windowHeight,
		headerHeight,
		navigation,
		adminBarOffset,
		navigationSize,
		navigationFixed = false,
		practitionerFixed = false,
		practitionerTop,
		practitionerNav,
		couponCode = false,
		couponTimeout = 0;

	var $navLinks = $(".main-navigation a"),
		$url = window.location.href;

	return {

		Initialise: function () {

			navigation = document.getElementById('main-navigation');
			practitionerNav = document.getElementById('practitioner-nav');

			DermatologyM.Menus();
			DermatologyM.Resize();

			var windowResize = DermatologyM.Helpers.throttle(DermatologyM.Resize, 50);
			if ( window.addEventListener ) {
				window.addEventListener( 'resize', windowResize, false );
				window.addEventListener( 'load', windowResize, false );
				window.addEventListener( 'hashchange', DermatologyM.Resize );
			}

			$(window).on('load scroll', DermatologyM.Header);
			DermatologyM.Header();

			$('.category__title a').on('click', function(e) {
				e.preventDefault();
				$( this ).closest( '.category' ).toggleClass( 'category--open' );
				DermatologyM.Resize();
			});

			$('.scroll').on('click', function(e) {
				e.preventDefault();
				var $to = $('.category:first');
				var offset = 38;

				if ( $to.length === 0 ) {
					$to = $('.products');
				}

				if ( $to.hasClass( 'category--quick' ) ) {
					offset = 119;
				}

				if ( $to.length > 0 ) {
					$('html, body').animate({scrollTop: $to.offset().top - offset}, 1500, 'easeInOutCubic');
				}
			});

			$('.quick-add-to-cart').on('submit', DermatologyM.QuickSubmit);

			$( document.body ).on( 'click', '.update-bag', DermatologyM.BagUpdate );
			$( document.body ).on( 'change', '.qty', DermatologyM.BagQty );
			$( document.body ).on( 'click', '.qty', DermatologyM.BagQty );
			$( document.body ).on( 'applied_coupon', DermatologyM.BagCoupon );
			$( document.body ).on( 'removed_coupon', DermatologyM.BagRemoveCoupon );
			$( document.body ).on( 'added_to_cart', DermatologyM.AddedToCart );
			$( document.body ).on( 'click', '.coupon--instance', DermatologyM.RemoveCouponClick );


			$( 'form.checkout' ).off( 'change', '#ship-to-different-address input');
			$( 'form.checkout' ).on( 'change', '#ship-to-different-address input', DermatologyM.CheckoutShippingFields );


			$('.page__header-content [href^="#"]').on('click', function(e) {
				e.preventDefault();
				var $to = $( $(this).attr('href') );
				var offset = 38;
				if ( $(this).attr('href').indexOf('gform_wrapper_1') != -1 ) {
					offset = 52;
				}

				if ( $to.length > 0 ) {
					$('html, body').animate({scrollTop: $to.offset().top - offset}, 1500, 'easeInOutCubic');
				}
			});

			$('.products .add_to_cart_button').addClass('ajax_add_to_cart');

			if ( $('.related-products').length > 0 ) {
				DermatologyM.Slick();
			}
			
			$('nav a[href^="/diplomas' + location.pathname.split("/diplomas")[1] + '"]').css({'color':'red'});
			
			$('.main-navigation a[href*="/account' + location.pathname.split("/account")[1] + '"]').css({'color':'red'});

			$('[type="file"]')
				.attr('accept', '.pdf,.doc,.docx')
				.on('change', function() {
					$(this).parent().attr('data-placeholder', $(this).val().replace(/C:\\fakepath\\/i, ''));
				})
				.parent()
					.attr('data-placeholder', 'Choose from computer...');

			$(document).bind('gform_post_render', function(){
				$('[type="file"]').each(function() {
					var $parent = $(this).parent();
					var $preview = $parent.find('.ginput_preview');
					if ( $preview.length > 0 ) {
						$parent.attr('data-placeholder', $preview.text());
					} else {
						$parent.attr('data-placeholder', 'Choose from computer...');
					}
				});

				$('[type="file"]')
					.attr('accept', '.pdf,.doc,.docx')
					.on('change', function() {
						$(this).parent().attr('data-placeholder', $(this).val().replace(/C:\\fakepath\\/i, ''));
					});
			});


			$( document ).off('click', '.shipping-calculator-button');
			$( document ).on('click', '.shipping-calculator-button', function() {
				$( '.shipping-calculator-form' ).toggle();
				$(this).toggleClass('calculator-open');
				return false;
			});

			$(document.body).on('checkout_error', function(data) {
				$('.woocommerce-error li').each(function() {
					var text = $(this).text();
					if ( text.indexOf('postcode') !== -1 ) {
						$('#billing_postcode_field')
							.addClass('woocommerce-invalid woocommerce-invalid-required-field woocommerce-invalid--postcode')
							.on('blur change', function() {
								$(this).removeClass('woocommerce-invalid--postcode');
							});
					}
				});
			});

			$(document.body).on('updated_checkout', DermatologyM.CheckoutWidth);
			DermatologyM.CheckoutWidth();


			if ( ! $(document.body).hasClass('checkout') && $('.woocommerce').length > 0 && $('.form-row').length > 0 ) {
				$('.woocommerce form').on( 'blur change', '.input-text, select, input:checkbox', function() {
					var $this     = $( this ),
						$parent   = $this.closest( '.form-row' ),
						validated = true;

					if ( $parent.is( '.validate-required' ) ) {
						if ( 'checkbox' === $this.attr( 'type' ) && ! $this.is( ':checked' ) ) {
							$parent.removeClass( 'woocommerce-validated' ).addClass( 'woocommerce-invalid woocommerce-invalid-required-field' );
							validated = false;
						} else if ( $this.val() === '' ) {
							$parent.removeClass( 'woocommerce-validated' ).addClass( 'woocommerce-invalid woocommerce-invalid-required-field' );
							validated = false;
						}
					}

					if ( $parent.is( '.validate-email' ) ) {
						if ( $this.val() ) {

							/* https://stackoverflow.com/questions/2855865/jquery-validate-e-mail-address-regex */
							var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);

							if ( ! pattern.test( $this.val()  ) ) {
								$parent.removeClass( 'woocommerce-validated' ).addClass( 'woocommerce-invalid woocommerce-invalid-email' );
								validated = false;
							}
						}
					}

					if ( validated ) {
						$parent.removeClass( 'woocommerce-invalid woocommerce-invalid-required-field' ).addClass( 'woocommerce-validated' );
					}
				});
			}

			if ( $(document.body).hasClass('account-page') && $('.woocommerce-error').length > 0 ) {
				$('.woocommerce-error li').each(function() {
					var selector = $(this).text()
									.replace(' is a required field.', '')
									.replace(' is required.', '')
									.replace('Error: ', '')
									.replace('Enter a ', '')
									.replace('.', '');

					if ( selector === 'Username' ) {
						selector = 'Username or email address';
					}

					if ( selector === 'username or e-mail address' ) {
						selector = 'Username or email address';
					}

					var $el = $('[data-required-label="' + selector + '"] input');
					$el.removeAttr('value').val('').trigger('blur');
				});
			}

			var $emailSignup = $('#subForm [type="email"]');

			$emailSignup.on('blur change', function() {
				$emailSignup.addClass('interaction');
			});


			$('#subForm').on('submit', function (e) {
				$emailSignup.trigger('change');

				if ( $emailSignup.val() === '' ) {
					e.preventDefault();
				}

				$.getJSON(
					this.action + "?callback=?",
					$(this).serialize(),
					function (data) {
						if (data.Status === 400) {
							$emailSignup.val('').trigger('change');
						} else {
							document.location.href = '/subscription-successful';
						}
					}
				);
				e.preventDefault();
			});

			if ( document.cookie.indexOf('dermatologym_country') === -1 ) {
				$.get('/wp-admin/admin-ajax.php?action=set_non_usa_message');
			}

			var $logo = $('.logo'),
				$searchDiv = $("#shop-search"),
				$searchArea = $searchDiv.find(".submit-field-embedded"),
				$searchField = $searchArea.find('input[type="text"]'),
				$searchButton = $searchArea.find("#search-button");

			$searchField.focus(function() {
				var text = $(this).val();

					$searchButton.addClass("search-button-focus");
					
					if(windowWidth <= 639) {
						$logo.addClass("logo-hide");
					}

					if(text !== ''){
						$(this).addClass('typing');
					} else {
						$(this).removeClass('typing');
					}
			});

			$searchField.on("blur", function() {
				$searchButton.removeClass("search-button-focus");
				if(windowWidth <= 639) {
					$logo.removeClass("logo-hide");
				}
			});

		},

		Menus: function () {

			$( '.navicon' ).on( 'click', function () {
				$( document.body ).toggleClass( 'main-nav-open' );
				$('.main-navigation ul').slideToggle();

			});


			var $menuItem = $('li.menu-item'),
				$menuItemLink = $menuItem.find('a'),
				$subMenu = $('.sub-menu'),
				$subMenuListItem = $subMenu.find('li'),
				$subMenuListItemLink = $subMenuListItem.find('a'),
				$categoryPanel = $('.category-panel');

			if($menuItem.has($subMenu)) {
				$menuItemLink.on('click', function(e) {
					e.preventDefault();
					$('li.menu-item > ul.sub-menu').toggleClass('active');
				});
			}

			if($subMenuListItem.has($categoryPanel)) {
				$subMenuListItemLink.on("click", function(e) {
					e.preventDefault();
					$('li.menu-item > ul.sub-menu').addClass('active');
					$categoryPanel.siblings().toggleClass('panel-active');
				});
			}

		},

		Header: function() {
			var scrollTop = $(window).scrollTop();
			if ( scrollTop >= headerHeight ) {
				if ( ! navigationFixed ) {
					DermatologyM.Helpers.addClass(document.body, 'fixed-header');
				}
				navigationFixed = true;
			} else {
				if ( navigationFixed ) {
					DermatologyM.Helpers.removeClass(document.body, 'fixed-header');
				}
				navigationFixed = false;
			}

			if ( scrollTop + navigationSize.height + 2 >= practitionerTop ) {
				if ( ! practitionerFixed ) {
					DermatologyM.Helpers.addClass(document.body, 'practitioner-fixed');
				}
				practitionerFixed = true;
			} else {
				if ( practitionerFixed ) {
					DermatologyM.Helpers.removeClass(document.body, 'practitioner-fixed');
				}
				practitionerFixed = false;
			}
		},

		Resize: function() {

			DermatologyM.Helpers.setDimensions();

			if ( $('.product-preview').length > 0 ) {

				$('.products').each(function() {
					var $children = $(this).children();
					var groupSize = windowWidth >= 560 ? ( windowWidth >= 800 ? 3 : 2 ) : 1;

					var groups = $.map($children, function(item, index){
						return index % groupSize === 0 ? $children.slice(index, index + groupSize) : null; 
					}).filter(function(item) {
						return item;
					});

					for (var i = groups.length - 1; i >= 0; i--) {
						DermatologyM.MatchHeight( $( groups[i] ).find('.product-preview__image') );
						DermatologyM.MatchHeight( $( groups[i] ).find('.product-preview__content') );
						DermatologyM.MatchHeight( groups[i] );
					}
				});

			}

			if ( $('.related-products').length > 0 ) {
				$('.related-products').css('width', $('.product-content').width() - parseFloat( $('.related-products').css( 'margin-left' ).replace('px', '') ) + 'px');
			}

		},

		MatchHeight: function(elements) {
			var $elements = $(elements);
			$elements.css('height', 'auto');
			var maxHeight = Math.max.apply(null, $elements.map(function() {
				return $(this).height();
			}).get());

			$elements.css('height', maxHeight + 'px');
		},

		QuickSubmit: function(e) {
			e.preventDefault();
			$(this).prev('button').data('quantity', $(this).find('[name="quantity"]').val()).trigger('click');
		},

		BagUpdate: function(e) {
			e.preventDefault();
			$('[name="update_cart"]').trigger('click');
			$(this).prev().removeClass('ready-to-go');
		},

		BagQty: function() {
			$(this).parent().addClass('ready-to-go');
		},

		AddedToCart: function(event, fragments, hash, $thisButton) {
			$thisButton.removeClass('added').parent().find('.added_to_cart').remove();
		},

		BagCoupon: function() {
			
			if ( ! couponCode ) {
				couponCode = $( '#coupon_code' ).val();
			}

			setTimeout(function() {
				if ( $('.coupon-' + couponCode).length > 0 ) {
					$('.coupon-container').prepend( '<div class="coupon coupon--instance"><span><strong>' + couponCode + '</strong><a href="/bag/?remove_coupon=' + couponCode + '" class="woocommerce-remove-coupon" data-coupon="' + couponCode + '">Remove</a></span></div>' );
					couponCode = false;
					couponTimeout = 0;
				} else {
					if ( couponTimeout < 10 ) {
						couponTimeout++;
						DermatologyM.BagCoupon();
					}
				}
			}, 1000);
		},

		BagRemoveCoupon: function(event) {
			if ( $('.woocommerce-error').length === 0 ) {
				$('.coupon--removed').remove();
			}
		},

		RemoveCouponClick: function() {
			$(this).closest('.coupon--instance').addClass('coupon--removed');
		},

		CheckoutShippingFields: function() {
			$( 'div.shipping_address' ).hide();
			if ( $( this ).is( ':checked' ) ) {
				$( 'div.shipping_address' ).show();
			}
		},

		CheckoutWidth: function() {
			$('.validate-required').each(function() {
				$(this).attr('data-required-label', $(this).find('> label').text().replace(' *', ''));
			});
		},

		Slick: function() {

			$('.related-products').slick({
				arrows: false,
				infinite: false,
				slidesToShow: 3,
				variableWidth: false,
				responsive: [
					{
						breakpoint: 700,
						settings: {
							slidesToShow: 2,
							variableWidth: true,
						}
					},
					{
						breakpoint: 400,
						settings: {
							slidesToShow: 1
						}
					}
				]
			});

		},

		Helpers: {

			setDimensions: function() {
				windowWidth = $(window).width();
				windowHeight = $(window).height();
				adminBarOffset = + ( $('#wpadminbar').length > 0 && $('#wpadminbar').css('position') === 'absolute' ? $('#wpadminbar').outerHeight() : 0 );
				headerHeight = $('[role=banner]').outerHeight() + adminBarOffset;
				navigationSize = navigation.getBoundingClientRect();
				if ( practitionerNav ) {
					var adminBarFixedOffset = ( $('#wpadminbar').length > 0 && $('#wpadminbar').css('position') === 'absolute' ? 0 : $('#wpadminbar').outerHeight() );
					practitionerTop = $( '#practitioner-nav' ).offset().top - adminBarFixedOffset;
				}
			},

			hasClass: function(el, className) {
				if (el.classList) {
					return el.classList.contains(className);
				} else {
					return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
				}
			},

			toggleClass: function(el, className) {
				if (el.classList) {
				  el.classList.toggle(className);
				} else {
					var classes = el.className.split(' ');
					var existingIndex = -1;
					for (var i = classes.length; i--;) {
					  if (classes[i] === className)
						existingIndex = i;
					}

					if (existingIndex >= 0)
					  classes.splice(existingIndex, 1);
					else
					  classes.push(className);

				  el.className = classes.join(' ');
				}
			},

			removeClass: function(el, className) {
				if (el.classList)
				  el.classList.remove(className);
				else
				  el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
			},

			addClass: function(el, className) {
				if (el.classList)
				  el.classList.add(className);
				else
				  el.className += ' ' + className;
			},

			throttle: function(callback, wait) {
				var time,
				ready = true;
				return function() {
					if(ready) {
						ready = false;
						time = setTimeout(function(){
							time = null;
							ready = true;
							callback.call();
						}, wait);
					}
				};
			},

			isVisible: function( el ) {
				var position = el.getBoundingClientRect();

				return (
					position.bottom >= 0 &&
					position.right >= 0 &&
					position.top <= (window.innerHeight || document.documentElement.clientHeight) &&
					position.left <= (window.innerWidth || document.documentElement.clientWidth)
				);
			}
		}
	};
}());

$( DermatologyM.Initialise );

jQuery.easing.jswing = jQuery.easing.swing;
jQuery.extend( jQuery.easing,
{
	def: 'easeOutQuad',
	swing: function (x, t, b, c, d) {
		return jQuery.easing[jQuery.easing.def](x, t, b, c, d);
	},
	easeOutQuad: function (x, t, b, c, d) {
		return -c *(t/=d)*(t-2) + b;
	},
	easeInOutCubic: function (x, t, b, c, d) {
		if ((t/=d/2) < 1) return c/2*t*t*t + b;
		return c/2*((t-=2)*t*t + 2) + b;
	},
});



// var MTIProjectId='9df73ec8-a386-43d5-ad8c-47cfa0743fe5';
// (function() {
// 	var mtiTracking = document.createElement('script');
// 	mtiTracking.type='text/javascript';
// 	mtiTracking.async='true';
// 	 mtiTracking.src='mtiFontTrackingCode.js';
// 	(document.getElementsByTagName('head')[0]||document.getElementsByTagName('body')[0]).appendChild( mtiTracking );
// })();